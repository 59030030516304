import { useEffect, useState } from "react";
import Screen from "../../organisms/dashboardScreen";
import Service from "./service";
const Dashboard = () => {
  const { dataDoctor, loading, listHospital, listSpecialization } = Service();
  const [initialData, setInitialData] = useState([]);

  useEffect(() => {
    if (!dataDoctor?.error) {
      setInitialData(dataDoctor?.data);
    } else {
      alert(dataDoctor?.message);
    }
  }, [dataDoctor]);
  console.log('data', dataDoctor);
  return (
    <div className="container">
      {loading ? (
        <span>Loading...</span>
      ) : (
        <div>
          <Screen
            listHospital={listHospital}
            listSpecialization={listSpecialization}
            data={initialData}
          />
        </div>
      )}
    </div>
  );
};

export default Dashboard;
