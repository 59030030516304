import axios from "axios"
import { useEffect, useState } from "react"
const Service = () => {
  const [dataDoctor, setDataDoctor] = useState(null);
  const [loading, setLoading] = useState(false);
  const [listHospital, setListHospital] = useState([]);
  const [listSpecialization, setListSpecialization] = useState([]);

  const getDataHospital = (params) => {
    let tempData = [];
    params?.forEach((item, index) => {
      params[index]?.hospital?.forEach((subItem, subIndex) => {
        tempData.push(subItem?.name)
      })
    })
    return [...new Set(tempData)]
  }
  const getDataSpecialization = (params) => {
    let tempData = [];
    params?.forEach((item, index) => {
        tempData.push(item?.specialization?.name)
    })
    return [...new Set(tempData)]
  }

  useEffect(() => {
    const fetchDataApi = async() => {
      setLoading(true);
      await axios.get(`https://run.mocky.io/v3/c9a2b598-9c93-4999-bd04-0194839ef2dc`).then((res) => {
        if(res?.status){
          setDataDoctor({message: res?.message, data: res?.data?.data, error: false});
          setListHospital(getDataHospital(res?.data?.data))
          setListSpecialization(getDataSpecialization(res?.data?.data))
          setLoading(false)
        }else{
          setDataDoctor({message: res?.message, data:[], error: true});
          setLoading(false);
        }
      }).catch((err) => {
        setDataDoctor({message: 'Network Error', data:[], error: true});
        setLoading(false);
      })
    }
    fetchDataApi();
  }, [])
  return {
    dataDoctor,
    listHospital,
    listSpecialization,
    loading,
  }
}

export default Service;