import { useState } from "react";
import { Input, SelectBar } from "../atoms";

const FormFilter = (props) => {
  const { listHospital, listSpecialization } = props;
  const [params, setParams] = useState({
    name: "",
    hospital: "",
    specialization: "",
  });

  return (
    <form
      style={{
        width: "100%",
        border: "2px solid",
        padding: "1em",
        display: "flex",
      }}
    >
      <div style={{width: '30%', display:'flex', padding:'0.5em'}}>
        <Input
          label="keyword"
          value={params.name}
          setValue={(e) => setParams({ ...params, name: e })}
        />
      </div>
      <div style={{width: '30%', display:'flex', padding:'0.5em'}}>
        <SelectBar
          label="Hospital"
          value={params.hospital}
          setValue={(e) => setParams({ ...params, hospital: e })}
          list={listHospital}
        />
      </div>
      <div style={{width: '30%', display:'flex', padding:'0.5em'}}>
        <SelectBar
          label="Specialization"
          value={params.specialization}
          setValue={(e) => setParams({ ...params, specialization: e })}
          list={listSpecialization}
        />
      </div>
    </form>
  );
};

export default FormFilter;
