import { useRef } from "react";
const Input = (props) => {
  const {
    label,
    value,
    setValue
  } = props;

  const ref = useRef(null);
  const renderStyle = () => {
    if(value === ''){
      return {
        zIndex: 10,
        position: 'absolute',
        left: 0,
        paddingLeft: '1em',
        top: '50%',
        transform: "translate(0, -50%)"
      }
    }else{
      return {
        zIndex: 10,
        position: 'absolute',
        paddingLeft: '1em',
        paddingRight: '1em',
        left: 0,
        top: -5,
        background:'white',
        fontSize: '0.7em'
      }
    }
  }
  return (
    <div style={{position: 'relative', width:'100%', display:'flex', border: '1px solid'}} onClick={() => ref.current.focus()}>
      <label style={renderStyle()}>{label || 'text'}</label>
      <input
        ref={ref}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        style={{width:'100%', padding: '1em', outline: 'none', border: 'none', position:'relative', background: ''}}
      />
    </div>
  )
}

export default Input;