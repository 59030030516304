import { useRef } from "react";

const SelectBar = (props) => {
  const {value, setValue, list, label} = props;
  const ref = useRef(null);
  return (
    <div style={{position: 'relative', width:'100%', display:'flex', border: '1px solid'}} onClick={() => ref.current.focus()}>
      <select value={value} onChange={(e) => setValue(e.target.value)} style={{outline: 'none', width:'100%', border: 'none'}}>
        <option value={""}>{label || 'Pilih'}</option>
        {list?.map((item, index) => (
          <option value={item}>{item}</option>
        ))}
      </select>
    </div>
  )
}

export default SelectBar;