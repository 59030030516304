import { CardDoctor, FormFilter } from "../molecules";
const Screen = (props) => {
  const {data, listHospital, listSpecialization} = props;

  const dataListDoctorHospital = (idx) => {
    const listHospital = data[idx]?.hospital.reduce((result, tag) => {
      return `${result}, ${tag?.name}`;
    });
    return listHospital;
  };
  return (
    <div className="content">
      <FormFilter
        listSpecialization={listSpecialization}
        listHospital={listHospital} 
      />
      {data && data?.map((item, index) => (
        <CardDoctor
          dataDoctor={data[index]}
          dataListDoctorHospital={dataListDoctorHospital(index)}
        />
      ))}

    </div>
  )
}

export default Screen;