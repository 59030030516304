import { useState } from "react";
import parse from "html-react-parser";
import PropTypes from 'prop-types';

const CardDoctor = (props) => {
  const { dataDoctor, dataListDoctorHospital} = props;
  const [data] = useState({
    photo: dataDoctor?.photo?.url,
    name: dataDoctor?.name,
    hospital: dataListDoctorHospital.name,
    specialization: dataDoctor?.specialization?.name,
    about: parse(dataDoctor?.about),
    price: dataDoctor?.price
  });


  // useEffect(() => {
  //   if(typeof(dataDoctor) === "object"){
  //     setData({
  //       photo: dataDoctor?.photo?.url,
  //       name: dataDoctor?.name,
  //       hospital: dataListDoctorHospital().name,
  //       specialization: dataDoctor?.specialization?.name,
  //       about: parse(dataDoctor?.about),
  //       price: dataDoctor?.price
  //     });
  //   }
  // }, [dataDoctor]);

  return (
    <div className="box">
      <div className="img-frame">
        <img src={data?.photo} alt="..." className="img-fluid" />
      </div>
      <div className="info-frame">
        <div className="data">
          <span className="text-bold">{data?.name}</span>
        </div>
        <div className="data">
          <small>{`${data?.hospital} - ${data?.specialization}`}</small>
        </div>
        <div className="data">
          <small>{data?.about}</small>
        </div>
        <div className="data">
          <span style={{float:'right', fontWeight: 'bold'}}>{dataDoctor?.price?.formatted}</span>
        </div>
      </div>
    </div>
  );
};

CardDoctor.propTypes = {
  dataDoctor: PropTypes.array,
}

CardDoctor.defaultProps = {
  dataDoctor: []
}

export default CardDoctor;
